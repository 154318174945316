<template>
  <div class="analysis-list-wrap">
    <el-tabs v-model="activeTab" class="demo-tabs" @tab-click="SET_CPAGE(1)">
      <el-tab-pane label="門市銷售" name="1"></el-tab-pane>
      <el-tab-pane label="商品銷售" name="2"></el-tab-pane>
      <el-tab-pane label="支付方式" name="3"></el-tab-pane>
      <el-tab-pane label="折扣概況" name="4"></el-tab-pane>
      <el-tab-pane label="客源概況" name="5"></el-tab-pane>
    </el-tabs>
    <el-radio-group v-model="status" class="mr-4">
      <el-radio-button label="rank">排行榜</el-radio-button>
      <el-radio-button label="trend">趨勢圖</el-radio-button>
    </el-radio-group>
    <rank v-if="status == 'rank'"></rank>
    <trend v-else></trend>

    <el-pagination
      v-if="showPagination() == true"
      class="pagination"
      v-model:currentPage="getCurrentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getDataLength"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import { ref, defineAsyncComponent, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { getFormatDate, formatDateTime } from '@/utils/datetime';
export default {
  name: 'analysis-list',
  components: {
    Rank: defineAsyncComponent(() => import('@/components/analysis/RankingList.vue')),
    Trend: defineAsyncComponent(() => import('@/components/analysis/Trend.vue')),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      getSearchCondition,
      getCurrentPage,
      getPageSize,
      getDataLength,
      getCurrentCategory,
      getDataAll,
      getCurrentTab,
    } = useGetters('analysis', [
      'getSearchCondition',
      'getCurrentPage',
      'getPageSize',
      'getDataLength',
      'getCurrentCategory',
      'getDataAll',
      'getCurrentTab',
    ]);
    const { SET_SEARCH_CONDITION, SET_CTAB, SET_CSTATUS, SET_CPAGE, SET_SEARCH_TEXT } = useMutations('analysis', [
      'SET_SEARCH_CONDITION',
      'SET_CTAB',
      'SET_CSTATUS',
      'SET_CPAGE',
      'SET_SEARCH_TEXT',
    ]);
    const { storeAnalysis, productAnalysis, paywayAnalysis, discountAnalysis, customerAnalysis, onePageData } =
      useActions('analysis', [
        'storeAnalysis',
        'productAnalysis',
        'paywayAnalysis',
        'discountAnalysis',
        'customerAnalysis',
        'onePageData',
      ]);
    const is_headquarters = sessionStorage.getItem('is_headquarters');

    // 取得資料
    async function getData() {
      if (activeTab.value == '1') await storeAnalysis();
      else if (activeTab.value == '2') await productAnalysis();
      else if (activeTab.value == '3') await paywayAnalysis();
      else if (activeTab.value == '4') await discountAnalysis();
      else if (activeTab.value == '5') await customerAnalysis();
    }

    // 類別轉換
    function conversion() {
      let error = false;
      if (activeTab.value == '1' || activeTab.value == '3') return '1';
      else if (activeTab.value == '2' || activeTab.value == '4' || activeTab.value == '5') {
        let match = false;
        getDataAll.value.forEach((data, index) => {
          if (data.name == getCurrentCategory.value[0]) {
            match = true;
            return index + 1;
          }
        });
        if (match == false) error = true;
      }
      if (error == true) console.log('網址有誤');
    }

    //初始化
    const status = ref('rank');
    watch(
      () => status.value,
      () => {
        if (status.value != '') SET_CSTATUS(status.value);
      },
      {
        immediate: true,
      }
    );
    const activeTab = ref('1');
    const query = {
      tab: '1',
      cate: '1',
      page: 1,
    };
    // 銷售分析條件搜尋初始值
    let searchDataInit = {
      shop: { id: '', erp_id: '', name: '' }, //格式: { id:'DYS0467', erp_id:'A467', name:'板橋中山店'}
      date: [
        formatDateTime('YYYYMMDD', getFormatDate().slice(0, 8) + '01'),
        formatDateTime('YYYYMMDD', getFormatDate()),
      ], //格式: ['20230901', '20230911']
      datef: [getFormatDate().slice(0, 8) + '01', getFormatDate()], //格式: ['2023/09/01', '2023/09/11']
    };
    router.push({ query, replace: true }).then(() => {
      SET_SEARCH_CONDITION(searchDataInit);
    });

    // router改變
    watch(
      () => route.query,
      async (newVal, oldVal) => {
        // console.log('newVal',newVal)
        // console.log('oldVal',oldVal)
        if (getSearchCondition.value) {
          if (newVal.tab != oldVal?.tab) {
            SET_SEARCH_TEXT('');
            SET_CTAB(newVal.tab);
            activeTab.value = route.query.tab;
            SET_CPAGE(Number(newVal.page));
            await getData();
          } else {
            if (newVal.cate != oldVal?.cate) SET_SEARCH_TEXT('');
            if (newVal.page != oldVal?.page) SET_CPAGE(Number(newVal.page));
            onePageData();
          }
        }
      }
    );

    // tab改變
    watch(
      () => activeTab.value,
      async () => {
        if (activeTab.value == undefined) return;
        status.value = 'rank';
        router.push({ query: { tab: activeTab.value, cate: '1', page: getCurrentPage.value } });
      }
    );

    // 條件改變
    watch(
      () => getSearchCondition.value,
      async () => {
        if (getSearchCondition.value != null) await getData();
      }
    );

    // 點擊分頁
    function handleCurrentChange(val) {
      SET_CPAGE(val);
      router.push({ query: { tab: activeTab.value, cate: conversion(), page: getCurrentPage.value } });
    }

    // 是否顯示頁碼
    function showPagination() {
      if (status.value == 'rank' && getSearchCondition.value) {
        if (getSearchCondition.value.shop.id == '' && is_headquarters == 'true' && getCurrentTab.value != '1')
          return false;
        else return true;
      } else {
        return false;
      }
    }

    return {
      status,
      activeTab,
      handleCurrentChange,
      getCurrentPage,
      getPageSize,
      SET_CPAGE,
      getDataLength,
      showPagination,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.analysis-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
